<template>
  <div class="clubDetail">
    <el-card class="box-card">
      <div class="item">
        <p>logo</p>
        <!-- <div><img :src="companyInfo.clubLogo" alt="" /></div> -->
        <div class="cover" v-if="companyInfo.clubLogo">
          <el-image
            style="width: 100%; height: 100%"
            :src="companyInfo.clubLogo"
            :preview-src-list="[companyInfo.clubLogo]"
          >
          </el-image>
        </div>
      </div>
      <div class="item">
        <p>俱乐部名称</p>
        <div class="item-text">{{ companyInfo.clubName }}</div>
      </div>
      <div class="item">
        <p>负责人</p>
        <div class="item-text">{{ companyInfo.responsiblePerson }}</div>
      </div>
      <div class="item">
        <p>负责人电话</p>
        <div class="item-text">{{ companyInfo.responsiblePersonPhone }}</div>
      </div>
      <div class="item">
        <p>封面</p>
        <div
          class="imgbox"
          v-if="companyInfo.coverArr && companyInfo.coverArr.length > 0"
        >
          <p class="coverimg" v-for="item in companyInfo.coverArr" :key="item">
            <!-- <img :src="item" alt="" /> -->
            <el-image
              style="width: 100%; max-height: 100%"
              :src="item"
              :preview-src-list="companyInfo.coverArr"
            >
            </el-image>
          </p>
        </div>
        <p v-else>暂无数据</p>
      </div>
      <div class="item">
        <p>营业日</p>
        <div class="item-text">{{ companyInfo.dayArr.join("/") }}</div>
      </div>
      <div class="item">
        <p>营业时间</p>
        <div class="item-text">{{ companyInfo.businessTime }}</div>
      </div>
      <div class="item">
        <p>地址</p>
        <div class="item-text">
          {{ companyInfo.cityPathName ? companyInfo.cityPathName : "暂无数据" }}
        </div>
      </div>
      <div class="item">
        <p>详细地址</p>
        <div class="item-text">{{ companyInfo.specificAddress }}</div>
      </div>
      <div class="item">
        <p>营业执照</p>
        <div class="cover" v-if="companyInfo.businessLicense">
          <el-image
            style="width: 100%; height: 100%"
            :src="companyInfo.businessLicense"
            :preview-src-list="[companyInfo.businessLicense]"
          >
          </el-image>
        </div>
        <!-- <div><img :src="companyInfo.businessLicense" alt="" /></div> -->
      </div>
      <div class="item">
        <p>简介</p>
        <div
          class="imgbox"
          v-if="
            companyInfo.clubIntroduceArr &&
            companyInfo.clubIntroduceArr.length > 0
          "
        >
          <p
            class="coverimg"
            v-for="item in companyInfo.clubIntroduceArr"
            :key="item"
          >
            <!-- <img :src="item" alt="" /> -->
            <el-image
              style="width: 100%; max-height: 100%"
              :src="item"
              :preview-src-list="companyInfo.clubIntroduceArr"
            >
            </el-image>
          </p>
        </div>
        <p v-else>暂无数据</p>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getClubDetail } from "@/api/club";
export default {
  data() {
    return {
      id: this.$route.query.id,
      companyInfo: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    // 获取基础数据
    async init() {
      const res = await getClubDetail({ id: this.$route.query.id });
      let { result } = res;
      result.coverArr = result.cover.split(",");
      result.dayArr = result.businessDays.split(",").map((el) => {
        return `周${this.numberToChinese(el)}`;
      });
      result.clubIntroduceArr = result.clubIntroduce.split(",");
      this.companyInfo = result;
    },
    numberToChinese(num) {
      let chineseNum = String(num);
      chineseNum = chineseNum
        .replace(/0/g, "零")
        .replace(/1/g, "一")
        .replace(/2/g, "二")
        .replace(/3/g, "三")
        .replace(/4/g, "四")
        .replace(/5/g, "五")
        .replace(/6/g, "六")
        .replace(/7/g, "日");

      return chineseNum;
    },
  },
};
</script>
<style lang="scss" scoped>
.clubDetail {
  .box-card {
    // width: 49%;
    box-sizing: border-box;
    border-radius: 4px;
    h2 {
      color: #333333;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 4px;
        height: 16px;
        background: #5e83f5;
        margin-right: 8px;
      }
    }
  }
  .item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    p {
      font-size: 16px;
      color: #696969;
      width: 200px;
      flex-shrink: 0;
    }
    .item-text {
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      font-weight: 600;
      flex: 1;
      width: 0;
      // display: block;
      word-break: break-all;
    }
    .cover {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .imgbox {
      display: flex;
      flex-wrap: wrap;
      .coverimg {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
</style>
